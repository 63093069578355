import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm, scale } from "../utils/typography"

import "../utils/global.css"

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <Layout>
        <SEO
          title={``}
          keywords={[`blog`, `ha4gu`]}
        />

        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          const featuredImage = node.frontmatter.featuredImage
          return (
            <div
              key={node.fields.slug}
              style={{
                marginBottom: rhythm(1 / 2),
                padding: rhythm(1 / 4),
                borderBottomLeftRadius: `.4em`,
                backgroundColor: `rgba(255, 255, 255, 0.7)`,
                display: `flex`,
                flexDirection: `row`,
                flexWrap: `nowrap`,
                justifyContent: `space-between`,
                alignItems: `center`,
              }}
            >
              <div
                className={`left`}
                style={{
                  flex: `10 1 auto`,
                }}
              >
                <span
                  className={`posts-date`}
                  style={{
                    display: `inline-block`,
                    backgroundColor: `#DA753C`,
                    boxShadow: `2px 3px 4px rgba(0, 0, 0, 0.25)`,
                    ...scale(-1 / 6),
                    marginLeft: rhythm(-1 / 2),
                    marginBottom:rhythm(1 / 2),
                    paddingLeft: rhythm(1 / 4),
                    paddingRight: rhythm(1 / 4),
                    color: `#DBD8D7`,
                    letterSpacing: `.1em`,
                  }}
                >
                  {node.frontmatter.date}
                </span>
                <h2
                  className={`posts-title`}
                  style={{
                    ...scale(1 / 3),
                    marginBottom: rhythm(1 / 4),
                  }}
                >
                  <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                    {title}
                  </Link>
                </h2>
                <p
                  style={{
                    marginBottom: 0,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: node.frontmatter.description || node.excerpt,
                  }}
                />
              </div>

              { featuredImage != null &&
                <Img
                  style={{
                    marginBottom: 0,
                    paddingBottom: 0,
                    flex: `0 0 100px`,
                  }}
                  fixed={featuredImage.childImageSharp.fixed} />
              }
            </div>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    noFeaturedImage: file(absolutePath: { regex: "/no_featured_image.png/" }) {
      childImageSharp {
        fixed(width: 100, height: 100, cropFocus: CENTER, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY/MM/DD(ddd)")
            title
            description
            featuredImage {
              childImageSharp {
                fixed(width: 100, height: 100, cropFocus: CENTER, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
